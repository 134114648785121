
import isEqual from 'lodash.isequal'
import * as Sentry from '@sentry/vue'
import { Component, Watch } from 'vue-property-decorator'
import LootManagerSettings from '@/components/settings/loot_manager.vue'
import LootSolverSettings from '@/components/settings/loot_solver.vue'
import NotificationsSettingsComponent from '@/components/settings/notifications.vue'
import ThemeSettings from '@/components/settings/theme.vue'
import UserDetailsSettings from '@/components/settings/user_details.vue'
import NotificationSettings from '@/interfaces/notification_settings'
import { SettingsErrors } from '@/interfaces/responses'
import User from '@/interfaces/user'
import SavageAimMixin from '@/mixins/savage_aim_mixin'

@Component({
  components: {
    LootManagerSettings,
    LootSolverSettings,
    NotificationsSettingsComponent,
    ThemeSettings,
    UserDetailsSettings,
  },
})
export default class Settings extends SavageAimMixin {
  activeTab = {
    details: true,
    theme: false,
    notifications: false,
    lootManager: false,
    lootSolver: false,
  }

  errors: SettingsErrors = {}

  lootManagerVersion = this.user.loot_manager_version

  notifications = {
    ...this.user.notifications,
  }

  token = this.user.token

  theme = this.user.theme

  username = this.user.username

  lootSolverGreed = this.user.loot_solver_greed

  mounted(): void {
    document.title = 'User Settings - Savage Aim'
  }

  // Url to send data to
  get url(): string {
    return `/backend/api/me/`
  }

  // Return the user object from the store
  get user(): User {
    return this.$store.state.user
  }

  changeLootManagerVersion(version: string): void {
    this.lootManagerVersion = version
  }

  changeLootSolverGreed(greed: boolean): void {
    console.log(this.lootSolverGreed, greed)
    this.lootSolverGreed = greed
  }

  changeNotification(data: {notification: keyof NotificationSettings, value: boolean}): void {
    this.notifications[data.notification] = data.value
  }

  changeTheme(theme: string): void {
    this.theme = theme
  }

  changeUsername(username: string): void {
    this.username = username
  }

  errorsInDetails(): boolean {
    return (this.errors.username?.length || 0) > 0
  }

  errorsInLootManager(): boolean {
    return (this.errors.loot_manager_version?.length || 0) > 0
  }

  errorsInLootSolver(): boolean {
    return (this.errors.loot_solver_greed?.length || 0) > 0
  }

  errorsInNotifications(): boolean {
    return (this.errors.notifications?.length || 0) > 0
  }

  errorsInTheme(): boolean {
    return (this.errors.theme?.length || 0) > 0
  }

  // Function called on page reload via websockets
  async load(): Promise<void> {
    // This function does nothing on purpose
  }

  // Reset the settings when the User changes
  @Watch('$store.state.user', { deep: true })
  reset(): void {
    this.token = this.$store.state.user.token
    this.theme = this.$store.state.user.theme
    this.notifications = { ...this.$store.state.user.notifications }
  }

  resetActiveTab(): void {
    this.activeTab.details = false
    this.activeTab.theme = false
    this.activeTab.notifications = false
    this.activeTab.lootManager = false
    this.activeTab.lootSolver = false
  }

  // Save the data into a new bis list
  async save(): Promise<void> {
    const body = JSON.stringify({
      theme: this.theme,
      notifications: this.notifications,
      loot_manager_version: this.lootManagerVersion,
      loot_solver_greed: this.lootSolverGreed,
      username: this.username,
    })

    try {
      const response = await fetch(this.url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
        body,
      })

      if (response.ok) {
        // Just give a message saying it was successful
        this.$notify({ text: 'Update successful!', type: 'is-success' })
        // Reset Errors
        this.errors = {}
      }
      else {
        super.handleError(response.status)
        this.errors = (await response.json() as SettingsErrors)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to update User Settings.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  showDetails(): void {
    this.resetActiveTab()
    this.activeTab.details = true
  }

  showLootManager(): void {
    this.resetActiveTab()
    this.activeTab.lootManager = true
  }

  showLootSolver(): void {
    this.resetActiveTab()
    this.activeTab.lootSolver = true
  }

  showNotifications(): void {
    this.resetActiveTab()
    this.activeTab.notifications = true
  }

  showTheme(): void {
    this.resetActiveTab()
    this.activeTab.theme = true
  }

  unsavedDetails(): boolean {
    return this.username !== this.user.username
  }

  unsavedLootManager(): boolean {
    return this.lootManagerVersion !== this.user.loot_manager_version
  }

  unsavedLootSolver(): boolean {
    return this.lootSolverGreed !== this.user.loot_solver_greed
  }

  unsavedNotifications(): boolean {
    return !isEqual(this.notifications, this.user.notifications)
  }

  unsavedTheme(): boolean {
    return this.theme !== this.user.theme
  }
}
