
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SettingsErrors } from '@/interfaces/responses'

@Component
export default class LootManagerSettings extends Vue {
  @Prop()
  errors!: SettingsErrors

  @Prop()
  lootManagerVersion!: string

  get dropdown(): HTMLSelectElement {
    return this.$refs.dropdown as HTMLSelectElement
  }

  changeLootManagerVersion(): void {
    this.$emit('changeLootManagerVersion', this.dropdown.value)
  }
}
