
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SettingsErrors } from '@/interfaces/responses'

@Component
export default class LootSolverSettings extends Vue {
  @Prop()
  errors!: SettingsErrors

  @Prop()
  lootSolverGreed!: boolean

  changeGreed(event: Event): void {
    const checkbox = event.target as HTMLInputElement
    const value = checkbox.checked
    this.$emit('changeLootSolverGreed', value)
  }
}
