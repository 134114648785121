
import { Component, Prop, Vue } from 'vue-property-decorator'
import NotificationSettings from '@/interfaces/notification_settings'

@Component
export default class NotificationsSettings extends Vue {
  @Prop()
  notifications!: NotificationSettings

  changeNotification(event: Event): void {
    const checkbox = event.target as HTMLInputElement
    const notif = checkbox.dataset.name as keyof NotificationSettings
    const value = checkbox.checked
    this.$emit('changeNotification', { notification: notif, value })
  }
}
