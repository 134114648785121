
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SettingsErrors } from '@/interfaces/responses'

@Component
export default class ThemeSettings extends Vue {
  @Prop()
  errors!: SettingsErrors

  explanationHidden = true

  @Prop()
  theme!: string

  get dropdown(): HTMLSelectElement {
    return this.$refs.dropdown as HTMLSelectElement
  }

  changeTheme(): void {
    this.$emit('changeTheme', this.dropdown.value)
  }

  toggleExplanation(): void {
    this.explanationHidden = !this.explanationHidden
  }
}
